/* tslint:disable */
/* eslint-disable */
/**
 * SBL API Glommen Mjøsen Skog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { GMServicesCommonQuestionsQuestionDefinition } from '../model';
// @ts-ignore
import { GMServicesCommonQuestionsQuestionTemplate } from '../model';
/**
 * QuestionApi - axios parameter creator
 * @export
 */
export const QuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionGetAllQuestionDefinitions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/questions/definitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionGetAllQuestionTemplates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/questions/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionGetQuestionTemplateById: async (templateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('questionGetQuestionTemplateById', 'templateId', templateId)
            const localVarPath = `/v1/questions/templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionApi - functional programming interface
 * @export
 */
export const QuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionGetAllQuestionDefinitions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GMServicesCommonQuestionsQuestionDefinition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionGetAllQuestionDefinitions(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['QuestionApi.questionGetAllQuestionDefinitions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionGetAllQuestionTemplates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GMServicesCommonQuestionsQuestionTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionGetAllQuestionTemplates(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['QuestionApi.questionGetAllQuestionTemplates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionGetQuestionTemplateById(templateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GMServicesCommonQuestionsQuestionTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionGetQuestionTemplateById(templateId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['QuestionApi.questionGetQuestionTemplateById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * QuestionApi - factory interface
 * @export
 */
export const QuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionGetAllQuestionDefinitions(options?: any): AxiosPromise<Array<GMServicesCommonQuestionsQuestionDefinition>> {
            return localVarFp.questionGetAllQuestionDefinitions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionGetAllQuestionTemplates(options?: any): AxiosPromise<Array<GMServicesCommonQuestionsQuestionTemplate>> {
            return localVarFp.questionGetAllQuestionTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionGetQuestionTemplateById(templateId: string, options?: any): AxiosPromise<GMServicesCommonQuestionsQuestionTemplate> {
            return localVarFp.questionGetQuestionTemplateById(templateId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionApi - object-oriented interface
 * @export
 * @class QuestionApi
 * @extends {BaseAPI}
 */
export class QuestionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionGetAllQuestionDefinitions(options?: RawAxiosRequestConfig) {
        return QuestionApiFp(this.configuration).questionGetAllQuestionDefinitions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionGetAllQuestionTemplates(options?: RawAxiosRequestConfig) {
        return QuestionApiFp(this.configuration).questionGetAllQuestionTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionGetQuestionTemplateById(templateId: string, options?: RawAxiosRequestConfig) {
        return QuestionApiFp(this.configuration).questionGetQuestionTemplateById(templateId, options).then((request) => request(this.axios, this.basePath));
    }
}

