import {ALLMA_JOB_TYPE_PRECLEARING_FELLING,
    ALLMA_JOB_TYPE_PRECLEARING_THINNING,
    ALLMA_JOB_TYPE_FERTILIZATION,
    ALLMA_JOB_TYPE_DITCH_CLEARING,
    ALLMA_JOB_TYPE_HOGGER,
    ALLMA_JOB_TYPE_SCARIFICATION,
    ALLMA_JOB_TYPE_SCARIFICATION_WITH_EXCAVATOR,
    ALLMA_JOB_TYPE_PLANTATION_CONTROL,
    ALLMA_JOB_TYPE_PLANTING,
    ALLMA_JOB_TYPE_TRACK_REMOVING,
    ALLMA_JOB_TYPE_SPRAYING,
    ALLMA_JOB_TYPE_STEMPRUNING,
    ALLMA_JOB_TYPE_ADDITIVE_PLANTING,
    ALLMA_JOB_TYPE_THINNING,
    ALLMA_JOB_TYPE_YOUNG_TREE_CARE,
    ALLMA_JOB_TYPE_SCARIFICATION_WITH_SOWING} from "~/constants/allmaJobs";

export const SALES_ORDER_TYPE_GROUND_PREPARATION = 1
export const SALES_ORDER_TYPE_YOUNG_TREE_CARE = 2
export const SALES_ORDER_TYPE_PLANTING = 3

export const getSalesOrderTypeFromAllmaJobType = (allmaJobTypeNumber: string): number => {
    switch (allmaJobTypeNumber) {
        case ALLMA_JOB_TYPE_PRECLEARING_FELLING:
            return 0 // TODO
        case ALLMA_JOB_TYPE_PRECLEARING_THINNING:
            return 0 // TODO
        case ALLMA_JOB_TYPE_FERTILIZATION:
            return 0 // TODO
        case ALLMA_JOB_TYPE_DITCH_CLEARING:
            return 0 // TODO
        case ALLMA_JOB_TYPE_HOGGER:
            return 0 // TODO
        case ALLMA_JOB_TYPE_SCARIFICATION:
            return SALES_ORDER_TYPE_GROUND_PREPARATION
        case ALLMA_JOB_TYPE_SCARIFICATION_WITH_EXCAVATOR:
            return 0 // TODO
        case ALLMA_JOB_TYPE_PLANTATION_CONTROL:
            return 0 // TODO
        case ALLMA_JOB_TYPE_PLANTING:
            return SALES_ORDER_TYPE_PLANTING
        case ALLMA_JOB_TYPE_TRACK_REMOVING:
            return 0 // TODO
        case ALLMA_JOB_TYPE_SPRAYING:
            return 0 // TODO
        case ALLMA_JOB_TYPE_STEMPRUNING:
            return 0 // TODO
        case ALLMA_JOB_TYPE_ADDITIVE_PLANTING:
            return 0 // TODO
        case ALLMA_JOB_TYPE_THINNING:
            return 0 // TODO
        case ALLMA_JOB_TYPE_YOUNG_TREE_CARE:
            return SALES_ORDER_TYPE_YOUNG_TREE_CARE
        case ALLMA_JOB_TYPE_SCARIFICATION_WITH_SOWING:
            return 0 // TODO
    }
}

