import {useLocalstorage} from "~/use/localStorage/store";
import {
    type GMServicesCommonQuestionsQuestionDefinition,
    type GMServicesCommonQuestionsQuestionTemplate, GMServicesCommonQuestionsQuestionTemplateType,
    QuestionApi
} from "~/gen/openapi/sblService";
import {useAxiosClient} from "~/use/axios/client";
import {notify} from "@gm/components";
import {merge} from 'lodash'
import type {FullContract} from "~/models/contract";
import {getSalesOrderTypeFromAllmaJobType} from "~/constants/salesOrder";

const { persist } = useLocalstorage()

const state = reactive({
    questionDefinitions: persist<GMServicesCommonQuestionsQuestionDefinition[]>('questionDefinitions', []),
    questionTemplates: persist<GMServicesCommonQuestionsQuestionTemplate[]>('questionTemplates', [])
})

const questionDefinitions = computed(() => state.questionDefinitions)
const questionTemplates = computed(() => state.questionTemplates)

const loadQuestionTemplates = async () => {
    const {axiosClient} = useAxiosClient()
    const questionApi = new QuestionApi(undefined, '', axiosClient.value)
    try {
        state.questionTemplates = (await questionApi.questionGetAllQuestionTemplates())?.data
    } catch (e) {
        console.error('Failed to fetch question templates: ', e)
        notify('Kunne ikke hente spørsmålsmal', {
            type: 'error',
            duration: 7500,
        })
    }
}

const getQuestionTemplateByType = (type: number, contract?: FullContract) => {
    const allmaJobTypesInContract = contract?.AllmaJobs?.map((aj) => aj.JobType || '0')
    // TODO: support action plans

    const salesOrderTypesInContract = allmaJobTypesInContract?.map((ajt) => getSalesOrderTypeFromAllmaJobType(ajt)) || []

    const templates = state.questionTemplates?.filter((template) => {
        return template.QuestionTemplateType as unknown as number === type && template.OrderTypes?.some((ot) => salesOrderTypesInContract?.includes(ot))
    })

    if (!!templates.length && templates.length > 1 ) {
        const mergedTemplates = merge({}, ...templates)
        return {
            Id: undefined,
            Name: null,
            QuestionTemplateType: type as unknown as GMServicesCommonQuestionsQuestionTemplateType,
            QuestionDefinitions: mergedTemplates.QuestionDefinitions as Array<GMServicesCommonQuestionsQuestionDefinition>,
            OrderTypes: null
        } as GMServicesCommonQuestionsQuestionTemplate
    }
    return templates[0]
}

const loadQuestionDefinitions = async () => {
    const {axiosClient} = useAxiosClient()
    const questionApi = new QuestionApi(undefined, '', axiosClient.value)
    try {
        state.questionDefinitions = (await questionApi.questionGetAllQuestionDefinitions())?.data
    } catch (e) {
        console.error('Failed to fetch question definitions: ', e)
        notify('Kunne ikke hente spørsmålsdefinisjoner', {
            type: 'error',
            duration: 7500,
        })
    }
}

const getQuestionDefinitionById = (id: string) => {
    return state.questionDefinitions?.find((question) => question.Id === id)
}

const sortQuestionDefinitionsByOrder = (questionDefinitions: Array<GMServicesCommonQuestionsQuestionDefinition>) => {
    return questionDefinitions.sort((a, b) => {
        if (!a.Order && !b.Order) return 0;
        if (!a.Order) return 1;
        if (!b.Order) return -1;
        return a.Order - b.Order;
    })
}

export const useQuestionStore = () => ({
    questionDefinitions,
    questionTemplates,
    loadQuestionDefinitions,
    loadQuestionTemplates,
    getQuestionDefinitionById,
    getQuestionTemplateByType,
    sortQuestionDefinitionsByOrder,
})